.pagination {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.slot {
  margin: 0;
  min-width: 80px;
}

.slotCreateSpace {
  composes: slot;
  margin-left: auto;
  margin-right: 10px;
}

.select {
  margin-bottom: 0;
  margin-right: 20px;
}
