:root {
  --zindex-snackbar: 500;
  --zindex-alert: 400;
  --zindex-overlay: 300;
  --zindex-topBar: 200;
  --zindex-content: 100;

  --white: #fff;
  --off-white: #f2f2f2;
  --warning-tag-bg: #fff9f8;
  --warning-tag-border: #c72e15;
  --info-tag-bg: #121212;
  --label-color: #32383d;
  --preferred-color: #d7fcfd;
  --clinical-orange: #ffa800;
  --clinical-orange-light: #fff3e0;
  --card-border: #e3eaed;
  --card-border-hover: #cfdbe1;
  --color-text-grey: #637280;
  --more-details-bg: #f3fafd;
  --timeline-path: #63728026;
  --dark-grey: #353a46;
  --neutral-grey: #647481;
  --click-down-grey: #d7dadc;
  --active-grey: #f3f6f9;
  --blue-grey: #c5d0de;
  --dark-purple: #2b065e;
  --grey-900: #32383d;
  --grey-800: #454d54;
  --grey-600: #647481;
  --grey-200: #bbc2c7;
  --grey-110: #d7dadc; // using 110 to avoid conflict with --grey-100 from core-ui which is a different scale
  --petroleum-teal: #005870;
  --teal-color: #2bbbbb;
  --babylon-violet: #400099;
  --transparent: transparent;
  --button-outline: #f9f9f9;
  --hovered-outlined-button: #f9f9f9;
  --tooltip: #273142;
  --text: #4a4a4a;
  --anchor-color: #8139ff;
  --hover-anchor-color: darken(var(--anchor-color), 20%);
  --highlight-blue: #326fec;
  --screen-mobile: 320px;
  --screen-small: 768px;
  --screen-medium: 1280px;
  --screen-large: 1440px;
  --shadow: #f3f6f9;
  --shadow-edge: #d7dadc;
}

$header-height: 44px;
$content-height: calc(100vh - #{$header-height});
$color-primary: #7e5ced;

$color-text-placeholder: #aaa;
$color-text-dark: #353a46;
$color-text-light: #6b7785;
$color-text-tag: #3861bf;
$color-background-tag: #e9f0fb;
$color-text-grey: #637280;

// ADMIN PORTAL REDESIGN COLORS:
$black: #30302c;
$dark-gray: #667482;
$mid-gray: #76838f;
$light-gray: #bfc3cb;
$accent-color: #2bbbbb;
$red-color: #ff4743;
$yellow-color: #bdaa1c;
$grey-1: rgba(99, 114, 128, 0.1);
$light-blue: #f7fafc;
$teal-color: #2bbbbb;
$active-grey: #f3f6f9;
$click-down-grey: #d7dadc;
$babylon-violet: #400099;
$white: #fff;

// OLD COLORS:
$babylon-purple: #7e5ced;
$border-light-1: #f0f0f0;
$border-light-2: #e9e9e9;
$border-light-3: #dfe7ec;

// Breakpoints:
$screen-mobile: 320px;
$screen-small: 768px;
$screen-medium: 1280px;
$screen-large: 1440px;

// other
$small-window-width: 1024px;
