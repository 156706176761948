.buttonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upperGrid {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.cells {
  align-items: flex-start;
  justify-content: flex-start;
}

.cellEdit {
  composes: cells;
  margin-left: auto;
  max-width: 100px;
}

.text {
  padding-bottom: 8px;
}

.AllowedProfessions {
  text-indent: 0;
  margin-left: 5px;
}

.list {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  list-style: none !important;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg {
  margin-right: 6px;
  align-self: baseline;
}

.intimacyFieldLabel {
  margin-top: 28px;
}
