.slot {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--active-grey);
}

.slotClickable {
  cursor: pointer;
}

a.slot:hover {
  color: var(--active-grey);
}
