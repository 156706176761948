.dateSelector {
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dateSelector :global .core-form-field {
  margin-bottom: 0;
}

.dateSelector :global .core-datepicker__input {
  height: 34px;
  line-height: 1.4;
}

.timezoneInput {
  width: 260px;
}

.timezoneInput :global .core-select__control {
  height: 34px;
  min-height: 0;
}

.timezoneInput :global .core-select__value-container {
  padding: 4px;
}

.datePickerIcon {
  margin-top: 28px;
}

.dayHasShift {
  background: var(--schedule);
}
