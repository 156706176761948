.header {
  padding: 6px 10px 6px 46px;
  border-bottom: solid 1px #ddd;
  line-height: 20px;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.95);
  color: #777;
  z-index: 10;
}
