.AvailabilityListingContainer {
  margin-top: 5em;
}

.SingleCol {
  padding: 1em;
  text-align: center;
}

.ColumnMissingDataText {
  color: rgba(0, 0, 0, 0.3);
}
