@import './BaseInput.variables';

@mixin BaseInputLabel {
  display: block;
  font-family: $base-input-label-font-family;
  color: $base-input-label-color;
  line-height: $base-input-label-line-height;
  transition: $transition-slow;
  margin-bottom: $base-input-label-margin;
  font-weight: bold;
}
