@import '../colors.css';

.panel {
  font-family: 'Inter', serif;
  font-style: normal;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 1px;

  flex: none;
  flex-grow: 0;
}

.panelRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.panelRow > dl {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 14px;
  line-height: 22px;
  gap: 4px;
}

.panelRow > dl dt {
  font-weight: 600;
  color: var(--color-text-heading);
}

.panelRow > dl dt::after {
  content: ':';
}

@media print {
  .panelRow > dl {
    border-bottom: 2px solid black;
  }
}

@media screen {
  .panel {
    background: var(--color-elements-border);
    border: 1px solid var(--color-elements-border);
    box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
  }

  .panelRow:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .panelRow:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .panelRow {
    background: var(--color-elements-background);
  }
}
