// from web-app

$font-family: 'Visuelt', sans-serif;
$shadow: 0 0 13px 0 rgba(203, 203, 203, 0.5);
$transition-time: 0.3s;
$transition-ease: ease-in-out;

// form @babylon/medkit

$border-radius-large: 14px;
$border-radius-medium: 8px;
$border-radius-small: 4px;
$transition-slow: 0.2s;
