.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form :global .core-form-label {
  color: var(--text);
}

.checkbox {
  --checkbox-checked-background-color: var(--babylon-violet);
  color: var(--medkit-input-label-color);
  margin-right: 30px;
}

.checkbox :global .core-checkbox__element {
  width: 18px;
  height: 18px;
}

.submitButton {
  background-color: var(--babylon-violet);
  font-size: 16px;
  margin-left: 20px;
}

.submitButton:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 2px;
}

.submitButton:hover,
.submitButton:active {
  background-color: var(--babylon-violet);
  opacity: 0.8;
}

.formButtonsBlock {
  display: flex;
  justify-content: flex-end;
}
