.CancelButton {
  margin: 1em auto 0;
  z-index: 1;
}

.AlertBox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
