.visibilityHeaderNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.visibilityHeaderNavigationLeft {
  padding-left: 0;
}

.visibilityHeaderNavigationRight {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 0;
}

.visibilityHeaderNavigationIconRight {
  margin-left: 14px;
}

/* Increasing the specificity because of a core-ui button style applied to the icon (last-child) */
.visibilityHeaderNavigationRight .visibilityHeaderNavigationIconRight {
  margin-right: 0;
}
