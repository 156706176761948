.AppointmentTypeName {
  margin-left: 10px;
}

.DurationDropdown {
  min-width: 250px;
  margin-right: 50px;
}

.buttonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.IntimacyField {
  --checkbox-checked-background-color: var(--babylon-violet);
  color: var(--medkit-input-label-color);
}
