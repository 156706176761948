@import '../../../../colors.css';

.patientPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  font-family: Inter, serif;
  border-left: var(--color-elements-border) 1px solid;
  min-width: 420px;
  z-index: 1;
}

.patientPanel > * {
  background: #fff;
  width: 100%;
}

.patientPanel > *:not(:last-child) {
  border-bottom: var(--color-elements-border) 1px solid;
}

.patientPanel > header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.patientPanel h1 {
  color: var(--color-text-heading);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.patientPanel h2 {
  color: var(--babylon-brand-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.panelBody {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
}

.toggleable > h2 {
  display: flex;
  justify-content: space-between;
}

.toggleable > h2 > button {
  border: none;
  background: none;
  cursor: pointer;
  transition: box-shadow 0.1s linear 0s;
  box-shadow: 0 0 0 0 var(--color-elements-border);
}

.toggleable > h2 > button:hover {
  box-shadow: 2px 2px 0 0 var(--color-elements-border);
}

.toggleable,
.patientPanel > header {
  border-bottom: var(--color-elements-border) 1px solid;
  padding: 16px 24px;
}

.patientPanel dl {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 14px;
  line-height: 22px;
  gap: 4px;
}

.patientPanel dl > dt {
  font-weight: 600;
  color: var(--color-text-heading);
}

.patientPanel dl > dt::after {
  content: ':';
}
