.ChooseList {
  overflow: auto;
  height: 190px;
  padding: 0;
  list-style-type: none;
  margin-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.SearchNoResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ChooseListItem:hover {
  background-color: #f9f9f9;
}

.ChooseListItem:not(:last-child) {
  margin-bottom: 12px;
}

.ChooseListItemButton {
  height: 44px;
  width: 100%;
  cursor: pointer;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: 0;
  padding: 0 5px;
}
