.key {
  border: 1px solid #e8e8e8;
  padding: 0 5px;
  align-self: flex-start;
  line-height: 0;
  vertical-align: middle;
  border-radius: 5px;
  height: 20px;
  display: flex;
  align-items: center;
}
