.durationSelector {
  display: flex;
  align-items: center;
  padding: 10px;
}

.buttonContainer {
  display: block;
  margin: 0;
}

.dateRange {
  display: block;
  margin: 0 20px;
  line-height: 20px;
}

.locationText {
  vertical-align: bottom;
}
