.page {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}

.calendar {
  height: 800px;
  margin-top: 32px;
}
