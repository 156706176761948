.modal {
  padding: 24px;
  display: flex;
}

.modal > * {
  margin: auto;
  max-width: 540px;
  height: 90vh;
}

@media print {
  .modal {
    padding: 0;
    display: flex;
  }

  .modal > * {
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
