.button {
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 24px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #e8e8e9;
  border-radius: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  transition: box-shadow 0.1s linear 0s;
  box-shadow: 0 0 0 0 #e8e8e9;
}

.button:hover {
  box-shadow: 2px 2px 0 0 #e8e8e9;
}

.animatedButton {
  transition: all 0.2s linear 0s;
  position: relative;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.animatedButton::before {
  content: '→';
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  transition: all 0.2s linear 0s;
}

.animatedButton:hover {
  text-indent: -9999px;
}

.animatedButton:hover::before {
  top: 0;
  text-indent: 0;
}
