.wrapper {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.grid {
  grid-template-rows: 1fr;
  margin-left: 20px;
  border-left: 1px solid #e0e6eb;
}

.clinicianGrid {
  width: 254px;
  overflow: hidden;
  position: absolute;
  left: 0;
}

.cell {
  height: 73px;
}

.gridCell {
  composes: cell;
  border-bottom: 1px solid #e0e6eb;
  border-right: 1px solid #e0e6eb;
  padding: 1px;
  min-width: 30px;
}

.timeCell {
  composes: gridCell;
  padding: 0;
  position: relative;
  height: 40px;
  width: 60px;
}

.time {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  line-height: 30px;
  transform: translateX(-50%);
}

.clinicianGrid .cell {
  border-bottom: 1px solid #e0e6eb;
  background-color: #fff;
  padding: 8px 0;
  padding-right: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid #e0e6eb;
}

.headerSlot {
  display: flex;
  composes: cell;
  background: transparent;
  margin-left: 0;
  margin-right: auto;
  height: 40px;
  width: 100%;
}

.headerText {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px;
}

.clinicianGrid .headerSlot {
  border-right: 0;
  background: transparent;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
}
