/* stylelint-disable */
/* COLORS */

$BORDER_COLOR: #dfe7eb;
$BACKGROUND_COLOR: #fff;
$BUTTON_BACKGROUND_COLOR: #7e5ced;
$BUTTON_COLOR: #fff;
$BUTTON_BORDER_COLOR: #7e5ced;
$BUTTON_OUTLINE_BACKGROUND_COLOR: #fff;
$BUTTON_OUTLINE_COLOR: #353a46;
$BUTTON_OUTLINE_BORDER_COLOR: #dfe7eb;
$BUTTON_ALERT_BACKGROUND_COLOR: #ffa7a7;
$BUTTON_ALERT_COLOR: #fff;
$BUTTON_ALERT_BORDER_COLOR: #ffa7a7;
$BUTTON_DISABLED_BACKGROUND_COLOR: #eaeaea;
$BUTTON_DISABLED_BORDER_COLOR: #bababa;
$BUTTON_DISABLED_COLOR: #8c8c8c;

$TEXT_COLOR: #6b7785;
$ICON_COLOR: #353a46;

$ALERT_COLOR: #f98b8b;
$ALERT_BORDER_COLOR: #f98b8b;
$ALERT_BACKGROUND_COLOR: #fcf5f5;

/* FONTS */

$FONT: 'Avenir', -apple-system, BlinkMacSystemFont, sans-serif;

/* STYLES */

fieldset {
  border: 1px solid $BORDER_COLOR;
  border-radius: 3px;
  padding: 15px 15px 5px 15px;
  margin: 0 0 15px 0;
  box-sizing: border-box;

  > legend {
    color: $TEXT_COLOR;
    font-size: 11pt;
    font-weight: 200;
    padding: 0 5px;
    font-family: $FONT;
  }
}

.widget-wrapper {
  display: block;
  margin-bottom: 15px;
  font-family: $FONT;
  line-height: 26px;

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: $FONT;
  }

  > label {
    display: block;
    color: $TEXT_COLOR;
    margin-bottom: 5px;
    font-size: 11pt;
    font-weight: 200;
    padding-left: 2px;
  }

  &.widget-alert {
    .border,
    .Select-control {
      border-color: $ALERT_BORDER_COLOR;
      background: $ALERT_BACKGROUND_COLOR;
    }
  }
}

.widget {
  font-size: 11pt;
  font-weight: 200;
  box-sizing: border-box;
  background: $BACKGROUND_COLOR;

  &.border {
    border: 1px solid $BORDER_COLOR;
    border-radius: 3px;
  }

  &.small {
    border-radius: 2px;
  }
}

.widget.select {
  position: relative;
  padding: 0;

  select {
    appearance: none;
    outline: none;
    width: 100%;
    border: 0;
    background: transparent;
    padding: 10px 14px;
    font-size: 11pt;
    font-weight: 200;
  }

  &:after {
    content: '';
    position: absolute;
    right: 14px;
    top: 14px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid $ICON_COLOR;
    pointer-events: none;
  }

  &.small {
    padding: 0 !important;
    line-height: 24px;

    select {
      font-size: 13px;
      padding: 0px 28px 0px 8px;
    }

    &:after {
      right: 9px;
      top: 9px;
      border-width: 4px;
      border-top-width: 6px;
    }
  }
}

textarea.widget,
input[type='text'].widget,
input[type='password'].widget,
input[type='search'].widget {
  outline: none;
  display: block;
  min-width: 100%;
  max-width: 100%;
  padding: 10px 14px;
}

textarea.widget {
  line-height: 1.6em;
}

.widget.small {
  font-size: 13px;
}

.widget.button,
.widget.input[type='button'],
.widget.input[type='submit'],
.widget.input[type='reset'] {
  background: $BUTTON_BACKGROUND_COLOR;
  border-color: $BUTTON_BORDER_COLOR;
  color: $BUTTON_COLOR;
  outline: none;
  white-space: nowrap;
  padding: 10px 14px;
  font-weight: bold;

  &:hover {
    background: darken($BUTTON_BACKGROUND_COLOR, 5);
    border-color: darken($BUTTON_BORDER_COLOR, 5);
  }

  &:active {
    background: darken($BUTTON_BACKGROUND_COLOR, 10);
    border-color: darken($BUTTON_BORDER_COLOR, 10);
  }

  &.outline {
    background: $BUTTON_OUTLINE_BACKGROUND_COLOR;
    border-color: $BUTTON_OUTLINE_BORDER_COLOR;
    color: $BUTTON_OUTLINE_COLOR;
    font-weight: lighter;

    &:hover {
      background: darken($BUTTON_OUTLINE_BACKGROUND_COLOR, 5);
      border-color: darken($BUTTON_OUTLINE_BORDER_COLOR, 5);
    }

    &:active {
      background: darken($BUTTON_OUTLINE_BACKGROUND_COLOR, 10);
      border-color: darken($BUTTON_OUTLINE_BORDER_COLOR, 10);
    }
  }

  &.alert {
    background: $BUTTON_ALERT_BACKGROUND_COLOR;
    border-color: $BUTTON_ALERT_BORDER_COLOR;
    color: $BUTTON_ALERT_COLOR;

    &:hover {
      background: darken($BUTTON_ALERT_BACKGROUND_COLOR, 5);
      border-color: darken($BUTTON_ALERT_BORDER_COLOR, 5);
    }

    &:active {
      background: darken($BUTTON_ALERT_BACKGROUND_COLOR, 10);
      border-color: darken($BUTTON_ALERT_BORDER_COLOR, 10);
    }
  }

  &:disabled {
    background: $BUTTON_DISABLED_BACKGROUND_COLOR;
    border-color: $BUTTON_DISABLED_BORDER_COLOR;
    color: $BUTTON_DISABLED_COLOR;
    font-weight: lighter;
  }

  &.small {
    padding: 5px 8px !important;
  }
}

input[type='checkbox'].widget,
input[type='radio'].widget {
  display: none;

  & + label {
    position: relative;
    padding-left: 25px;

    &:before {
      content: '';
      display: block;
      border: 1px solid #ccc;
      border-radius: 3px;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }

  &:checked + label {
    &:before {
      border-color: $BUTTON_BACKGROUND_COLOR;
      background-color: $BUTTON_BACKGROUND_COLOR;
    }

    &:after {
      content: '';
      display: block;
      width: 6px;
      height: 10px;
      border-bottom: 1px solid $BUTTON_COLOR;
      border-right: 1px solid $BUTTON_COLOR;
      transform: rotate(45deg);
      position: absolute;
      top: 5px;
      left: 6px;
    }
  }
}

input[type='radio'].widget {
  & + label {
    position: relative;
    padding-left: 25px;

    &:before {
      border-radius: 18px;
    }
  }
  &:checked + label {
    &:before {
      border-color: $BUTTON_BACKGROUND_COLOR;
      background-color: $BUTTON_BACKGROUND_COLOR;
    }

    &:after {
      border: 0;
      background-color: $BUTTON_COLOR;
      width: 8px;
      height: 8px;
      border-radius: 18px;
      top: 9px;
      left: 6px;
    }
  }
}

.field-alert {
  color: $ALERT_COLOR;
  font-size: 10pt;
  display: block;
  height: 1em;
  padding-left: 2px;
  margin-top: 5px;
}

/* stylelint-enable */
