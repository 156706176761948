.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  color: var(--text-color);
  background: #f5f9fe;
  overflow: hidden;
  cursor: pointer;
  font-size: 14px;
}

.button::before {
  display: block;
  content: '';
  width: 7px;
  height: 8px;
  background: url('data:image/svg+xml;utf8,<svg width="7" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-9-8h24v24H-9z"/><path d="M2.648 7.67L6.03 4.784c.51-.434.51-1.136 0-1.57L2.648.327C1.825-.374.414.127.414 1.118V6.89c0 .991 1.41 1.482 2.234.78z" fill="%23637280" fill-rule="nonzero"/></g></svg>');
  transition: transform 0.15s linear;
}

.expanded::before {
  transform: rotateZ(90deg);
}

.collapsed {
  display: inline-flex;
  gap: 8px;
  padding: 2px 12px;
  min-height: 28px;
  width: auto;
}

.hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
