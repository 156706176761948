.ReallocationDateClinicianShiftCell {
  align-items: center;
}

.ReallocationDateClinicianShiftCell:first-child {
  border-right: 1px solid #e3e3e3;
}

.ReallocationShiftGrid {
  min-width: 800px;
}

.ReallocationShiftPage {
  margin-bottom: 60px;
}
