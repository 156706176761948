/* stylelint-disable */
/* COLORS */

$BORDER_COLOR: #dfe7eb;
$SECONDARY_LIGHT: #e0e6ed;
$OUTLINE_DARK: #3b4859;

.data-table {
  &.border {
    padding: 0;
  }

  .sort-button {
    margin-left: 10px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    min-width: 1.2em;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    vertical-align: top;
    padding: 5px 20px;
    border-bottom: 1px solid #dfe7eb;
    font-size: 16px;
    text-align: left;
  }

  th {
    border: 0;
    box-shadow: inset 0 -1px 0 0 #dfe7eb;
    position: sticky;
    top: -1px;
    color: #666;
    background: #fff;
  }

  th.actions,
  td.actions {
    width: 1%;
    .widget-wrapper {
      margin: 0;
    }

    button,
    button.outline {
      border: 0;
      background: transparent;
      color: #919191;
      cursor: pointer;

      i {
        color: #666;
      }

      &:hover {
        background: transparent;
        color: #484848;

        i {
          color: #484848;
        }
      }

      &:active {
        background: transparent;
        color: #000;

        i {
          color: #000;
        }
      }
    }
  }

  td.empty-list-message {
    height: 100px;
    text-align: center;
    vertical-align: middle;
    color: #ababab;
  }
}

.widget.select {
  display: inline-block;
  width: 100%;

  select.empty {
    color: #aeaeae;
  }
}

.widget.search-box.border {
  border-radius: 50px;
  padding-right: 6px;

  input {
    outline: none;
    border: 0;
    background: transparent;
    padding: 6px 6px 6px 14px;
  }
}

.widget-wrapper.widget-alert
  .widget
  .Select.is-focused:not(.is-open)
  > .Select-control {
  border-color: #f98b8b;
  background: #fcf5f5;
}

.widget.autocomplete {
  .Select.is-focused:not(.is-open) > .Select-control {
    border-color: #319bf5;
    box-shadow: none;
  }

  .Select-control {
    line-height: 36px;

    &:hover {
      box-shadow: none;
    }
  }

  .Select-placeholder {
    line-height: 37px;
  }

  .Select-value {
    line-height: 36px;
  }

  .Select-clear-zone {
    top: 1px;
  }

  .Select-input {
    height: 21px;
    vertical-align: top;

    input {
      padding: 0;
    }
  }

  .Select-control,
  .Select-menu-outer {
    border: 1px solid $BORDER_COLOR;
    border-radius: 3px;
  }

  .Select-menu-outer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
    box-shadow: none;

    .option-error {
      font-size: 14px;
      color: #ff4743;
    }
  }

  .Select--multi .Select-value {
    line-height: 17px;
    height: 20px;
    margin-top: 8px;
    border: 0;
    background: #8ab4e4;
    color: #fff;
    font-weight: normal;

    .Select-value-icon {
      border-color: rgba(255, 255, 255, 0.5);
      line-height: 19px;
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        color: #fff;
      }
    }

    .Select-value-label {
      font-size: 14px;
      vertical-align: top;
      line-height: 18px;
    }
  }

  &.small {
    font-size: 13px;

    .Select-control {
      height: 27px;
      line-height: 26px;
      border-radius: 2px;
    }

    .Select-placeholder {
      line-height: 28px;
    }

    .Select-value {
      line-height: 26px;
    }

    .Select-menu-outer {
      border-radius: 2px;
    }

    .Select-noresults,
    .Select-option {
      padding: 2px 10px;
    }

    .Select--multi .Select-value {
      line-height: 12px;
      margin-top: 4px;
    }

    .Select--multi .Select-value {
      line-height: 12px;
      height: 16px;
      margin-top: 5px;

      .Select-value-icon {
        font-size: 14px;
        line-height: 14px;
        padding-left: 4px;
        padding-right: 3px;
      }

      .Select-value-label {
        line-height: 12px;
        font-size: 12px;
      }
    }
  }

  &.optionsAbove .Select-menu-outer {
    position: absolute;
    top: auto;
    bottom: calc(100% - 1px);
    border-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.small.optionsAbove .Select-menu-outer {
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.widget.options {
  position: relative;
  text-align: left;
  padding: 0;

  > div {
    padding: 3px 8px;
    cursor: default;

    &:hover,
    &:focus {
      background-color: #ddf9ff;
    }
  }
}

.widget.tab-group {
  background: transparent;
  position: relative;

  .tab-list {
    display: flex;
    flex-direction: row;
    position: relative;
    border-bottom: 2px solid $SECONDARY_LIGHT;

    .tab {
      height: 52px;
      line-height: 52px;
      cursor: pointer;
      user-select: none;
      transition: opacity 400ms;
      color: $OUTLINE_DARK;
      outline: none;

      &:focus {
        outline: none;
      }

      &:not(:last-child) {
        margin-right: 45px;
      }

      &:not(.active) {
        opacity: 0.8;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .pointer {
    height: 2px;
    width: 40px;
    border-radius: 1px;
    background: $OUTLINE_DARK;
    transition: all 450ms;
  }

  .tab-panel {
    padding-top: 20px;
    position: relative;
  }
}

.more-options {
  background: transparent;
  position: relative;

  .options-wrapper {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: absolute;
    z-index: 3000;
    min-width: calc(100% + 20px);
    right: -20px;

    i {
      width: 20px;
      display: inline-block;
    }
  }

  .options-wrapper.hidden {
    display: none;
  }

  div[role='button'] {
    white-space: nowrap;
  }
}

.pagination {
  button {
    margin: 0;
    padding: 0;
    width: 50px;
    height: 27px;
    line-height: 26px;
    background: #fff;
    border: 1px solid #dfe7eb;
    border-right: 0;
    color: #45576e;
    font-size: 14px;
    font-weight: 200;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-right: 1px solid #dfe7eb;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .current-page {
    color: #343a46;
    font-weight: 400;
  }
}

.dialog {
  background: #fff;
  width: 600px;
  padding: 30px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
}

.pagination-size {
  display: flex;
  align-items: center;

  select {
    font-size: 15px;
    outline: none;
    border: 1px solid #dfe7eb;
    border-radius: 1px;
    background: #fff;
    font-weight: 300 !important;
    margin: 0 10px;
  }

  > span {
    margin-right: 10px;
  }
}

.layout-row {
  display: flex;
  flex-direction: row;

  &.center {
    align-items: center;
  }

  &.end {
    align-items: flex-end;
  }

  &.stretch {
    justify-content: space-between;
  }

  &.even > * {
    flex: 1;
    margin-right: 15px;
  }

  > *:last-child {
    margin-right: 0;
  }
}

.date-range-picker {
  .DateRangePickerInput {
    border: 1px solid $BORDER_COLOR;
    border-radius: 3px;
  }
  .DateRangePickerInput_arrow svg {
    width: 17px;
    height: 17px;
    margin-top: -3px;
    margin-left: -1px;
  }
  .DateInput_input__focused {
    border-color: #7e5ced;
  }
  .DateRangePickerInput_clearDates {
    margin-right: 4px;

    svg {
      margin-top: -3px;
      fill: #666;
    }
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: visible;

    svg {
      fill: #ccc;
    }
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: transparent;
  }
  .DateRangePickerInput_clearDates:not(.DateRangePickerInput_clearDates__hide):hover {
    svg {
      fill: #000;
    }
  }
  .CalendarMonth_caption {
    font-size: 15px;
    color: #666;

    strong {
      font-weight: normal;
    }
  }
  .CalendarDay {
    outline: none;
  }
  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background-color: #7e5ced;
    color: #ffffff;
    border-color: #e4e7e7;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__selected_span {
    background-color: #cabafc;
    color: #7e5ced;
    border-color: #e4e7e7;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    border-right-color: #7e5ced;
  }
}

.loaderContainer {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
}

/* Spinner from <https://projects.lukehaas.me/css-loaders/> */
.loader {
  position: relative;
  top: -6em;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background: #48009f;
  background: -moz-linear-gradient(
    left,
    #48009f 10%,
    rgba(255, 31, 182, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #48009f 10%,
    rgba(255, 31, 182, 0) 42%
  );
  background: -o-linear-gradient(left, #48009f 10%, rgba(255, 31, 182, 0) 42%);
  background: -ms-linear-gradient(left, #48009f 10%, rgba(255, 31, 182, 0) 42%);
  background: linear-gradient(to right, #48009f 10%, rgba(255, 31, 182, 0) 42%);
  -webkit-animation: load3 0.95s infinite linear;
  animation: load3 0.95s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #48009f;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: #fdfffe;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* stylelint-enable */
