.legacyScreen,
.historylessIFrame {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vh;
}

.historylessIFrame {
  border: 0;
  flex-grow: 99;
}
