.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.value {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
