.appointment {
  display: flex;
  gap: 24px;
}

.times {
  text-align: right;
  padding-top: 7px;
}

.time {
  font-weight: bold;
  font-size: 16px;
}

.duration {
  font-size: 14px;
}

.card {
  display: flex;
  flex-grow: 1;
  border-radius: 4px;
  border: 1px solid var(--shadow-edge);
  overflow: hidden;
}

.type {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  padding: 20px 0;
  color: var(--black);
}

.typeNonCancellable {
  background-color: var(--grey-200);
  color: var(--white);
}

.detailContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 16px;
}

.details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 16px;
}

.detailsCollapsed {
  gap: 12px;
}

.patientName {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  color: var(--babylon-violet);
}

.hide {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.expander {
  width: 100%;
  order: 3;
}

.cancelButton {
  margin-left: auto;
  align-self: center;
  border: 0;
  font-size: 20px;
  background-color: var(--transparent);
  color: var(--grey-900);
  cursor: pointer;
}

.cancelButton:hover {
  opacity: 0.8;
}

.contentBox {
  display: flex;
  gap: 16px;
  background-color: var(--active-grey);
  font-size: 16px;
  border-radius: 4px;
  padding: 16px;
}

.contentArea {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
}

.tags {
  display: flex;
  align-items: center;
  gap: 8px;
  list-style-type: none;
  margin: 0;
  background-color: var(--white);
  padding: 8px;
  border-radius: 4px;
  margin-left: auto;
}

.tag {
  line-height: 1;
  font-size: 12px;
  border-radius: 2px;
  padding: 4px 6px;
  text-transform: uppercase;
  font-weight: 800;
}

.blueTag {
  color: var(--teal-color);
  background-color: #eaf9f9;
}

.redTag {
  color: var(--white);
  background-color: #fa4d56;
}

.orangeTag {
  color: #f4ab3c;
  background-color: #fdf2e2;
}

.buttons {
  padding: 24px;
}

.subHeading {
  font-size: 16px;
  margin: 0 0 4px;
}

.reasonsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.expandContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.link {
  font-weight: bold;
  text-decoration: underline;
  color: var(--babylon-violet);
}
