.core-select {
  &--is-disabled {
    opacity: 0.6;
  }

  .core-select__control {
    border: 1px solid var(--input-border-color);
    border-radius: 3px;
    color: var(--input-color);
    min-height: 32px;

    &:hover {
      border: 1px solid var(--input-hover-border-color);

      .core-select__indicator-separator {
        background-color: var(--input-hover-border-color);
      }
    }

    &--is-focused,
    &--is-focused:hover,
    &:focus {
      box-shadow: 0 0 0 2px var(--input-active-outline-color);
      border: 1px solid var(--input-active-border-color);

      .core-select__indicator-separator {
        background-color: var(--input-active-border-color);
      }
    }

    &--is-disabled {
      background-color: #fff;
    }

    &.core-select__control--menu-is-open {
      border-radius: 3px 3px 0 0;
      border-bottom: 0 none transparent;
    }
  }

  .core-select__value-container {
    padding: 6px 4px;

    &--is-multi {
      padding: 0 6px;
    }

    > div:not(.core-select__single-value) {
      padding: 0;
      margin: 0 4px;
    }
  }

  .core-select__single-value {
    margin: 0 4px;
  }

  .core-select__placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  .core-select__menu {
    margin: 0;
    border: 1px solid var(--input-active-border-color);
    border-top: 0 none transparent;
    border-radius: 0 0 3px 3px;
    box-shadow: none;
    z-index: 2;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 8px;
      right: 8px;
      height: 1px;
      background-color: var(--input-border-color-light);
      z-index: 1;
    }
  }

  .core-select__menu-list {
    padding-top: 1px;
    padding-bottom: 0;
  }

  .core-select__group {
    padding: 0;

    &:first-child {
      padding-top: 8px;
    }

    .core-select__option {
      padding-left: 24px;
    }
  }

  .core-select__group-heading {
    padding-left: 8px;
    margin: 4px 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--grey-100);
    text-transform: none;
  }

  .core-select__option {
    padding: 8px;
    font-size: 14px;
    color: var(--grey-100);

    &:hover,
    &--is-focused {
      color: var(--grey-100);
      background-color: var(--background-blue-light);
    }

    &--is-selected {
      background-color: #fff;
      color: var(--type-blue);
      font-weight: 600;
      line-height: 17px;

      &:hover,
      &.core-select__option--is-focused {
        color: var(--type-blue);
        background-color: var(--background-blue-light);
      }
    }

    &--is-disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .core-select__indicators {
    padding-right: 4px;
  }

  .core-select__indicator-separator {
    display: none;
  }

  .core-select__indicator {
    color: var(--light-grey-color);
    padding: 4px;

    svg {
      fill: var(--light-grey-color);
    }

    &:hover {
      color: var(--grey-100);

      svg {
        fill: var(--grey-100);
      }
    }
  }

  .core-select__spinner,
  .core-select__spinner .core-spinner__body {
    width: 16px;
    height: 16px;
  }

  .core-select__tag {
    font-size: 12px;
    font-weight: 800;
    line-height: 22px;
    margin: 4px 2px;
    text-transform: uppercase;
  }

  .core-select__menu-notice {
    padding: 8px;
    text-align: left;
  }
}
