.side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.status {
  height: 23px;
  text-transform: uppercase;
  padding: 2px 4px;
  margin-bottom: 4px;
  box-sizing: border-box;
}

.actions {
  position: relative;
}

.actionsList {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  background-color: var(--background-neutral);
  min-width: 200px;
  white-space: nowrap;
  box-shadow: 0 2px 8px 0 rgba(40, 40, 36, 0.3);
}

.actionsList a {
  padding: 8px 16px;
  color: inherit;
}
