.ShowCliniciansList,
.ShowProfessionsList,
.ShowSupplyNetworksList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 150px;
  overflow: auto;
}

.DisplayCard,
.DisplayCard + .DisplayCard {
  margin-top: 0;
  position: relative;
}

.AvailabilityLabel {
  margin-right: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.hidden {
  display: none;
}

.spinner {
  position: absolute;
}

.ListingSeparator {
  margin-top: 5em;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dateRangePicker {
  position: relative;
  z-index: 2;
}
