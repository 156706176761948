.filterLabelText {
  display: inline-block;
  margin-right: 10px;
}

.filterInput {
  display: inline-block;
  max-width: 435px;
  width: 100%;
}

.card {
  margin-top: 24px;
}

.nextSteps {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.memberInstructions {
  margin-right: 40px;
  width: 100%;
}
