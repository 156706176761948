.ShiftSlot {
  border-left: 5px solid;
  background-color: var(--white);
}

.ShiftSlotbooked {
  border-left-color: var(--medical-blue);
}

.ShiftSlotavailable {
  border-left-color: var(--teal-color);
}

.ShiftSlotadmin,
.ShiftSlotblocked {
  border-left-color: var(--grey-75);
}

.ShiftSlotWarning {
  border-color: var(--error-color);
  padding-right: 8px;
}

.ShiftSlotIcon {
  /* Increasing z-index because shift range overlaps
   * tooltip, preventing triggering.
   */
  z-index: 1;
}
