.container {
  flex: auto;
  text-align: center;
  background: white;
  border-radius: 8px;
  min-height: 30vh;
  display: grid;
  border-top: 0;
  place-content: center;
  height: calc(100vh - 360px);
}

.container button {
  display: inline-block;
  width: 100%;
}
