.breadcrumb {
  color: #7c7c7c;
  font-weight: bold;
}

.heading {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.container {
  padding: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 500px;
  font-size: 14px;
  line-height: 1.5;
}

.appointments {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.error {
  color: var(--warning-tag-border);
  text-align: right;
}

.loadMoreButton {
  margin: 0 auto;
}

.cancelInformation {
  margin-bottom: 24px;
}

.filterActions {
  display: flex;
  justify-content: space-between;
}

.filterButton {
  height: 35px;
  align-self: end;
}

.buttonActions {
  display: flex;
}
