.headSection {
  margin: 20px;
  position: relative;
  vertical-align: middle;
}

.dialogTitle {
  font-weight: bold;
  color: var(--heading-color);
}
