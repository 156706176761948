.appointmentsMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--shadow-edge);
  min-height: 700px;
  border-radius: 4px;
}

.content {
  max-width: 200px;
  text-align: center;
}

.heading {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

.message {
  margin: 0;
  font-size: 16px;
}
