.body {
  position: relative;
  margin: 0 20px;
  flex: 1;
  overflow-y: hidden;
}

.left {
  overflow-y: scroll;
}

.right {
  display: inline-block;
  vertical-align: top;
}

.commonDropdown {
  margin-bottom: 10px;
  width: 100%;
}

.commonDropdown label {
  color: var(--grey-75);
}
