.ChooseClinicianModal {
  width: 460px;
}

.ChooseClinicianModalInputSearch {
  margin: 16px 0 24px 0;
}

.ChooseClinicianModalList {
  overflow: auto;
  height: 190px;
  padding: 0;
  list-style-type: none;
}

.ChooseClinicianModalListItem:hover {
  background-color: #f9f9f9;
}

.ChooseClinicianModalListItem:not(:last-child) {
  margin-bottom: 12px;
}

.ChooseClinicianModalListItemButton {
  height: 44px;
  width: 100%;
  cursor: pointer;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: 0;
  padding: 0;
}

.ClinicianSearchNoResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ClinicianSearchNoResultMessage {
  margin-top: 10px;
}
