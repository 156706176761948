// from web-app

$min-width: 320px;

$body-line-height: 1.4;

$main-padding-h: 1.25rem;
$main-padding-v: 1.25rem;
$main-padding-h-small-break: $main-padding-h;
$main-padding-v-small-break: 3.125rem;
$main-padding-h-medium-break: $main-padding-h-small-break;
$main-padding-v-medium-break: $main-padding-v-small-break;
$main-padding-h-large-break: $main-padding-h-medium-break;
$main-padding-v-large-break: $main-padding-v-medium-break;

$space--xs: 4px;
$space--s: 8px;
$space--m: 16px;
$space--l: 24px;
$space--xl: 32px;
$space--xxl: 48px;

// TODO: relate this back to the actual height implementations,
// as currently these are just tallied totals based on the rendered UI
$header-height: 3.1875rem;
$crumb-height: 2.5rem;
$footer-height: 10.375rem;

$header-height-small-break: $header-height;
$crumb-height-small-break: 2.75rem;
$footer-height-small-break: 9.375rem;

$header-height-medium-break: 4rem;
$crumb-height-medium-break: $crumb-height-small-break;
$footer-height-medium-break: 7.875rem;

$header-height-large-break: $header-height-medium-break;
$crumb-height-large-break: $crumb-height-medium-break;
$footer-height-large-break: 6.5rem;

// from @babylon/medkit

// SIZES
$size-base: 0.25rem; // 4px
$size-xx-small: $size-base; // 4px
$size-x-small: $size-base * 2; // 8px
$size-small: $size-base * 3; // 12px
$size-medium: $size-base * 4; // 16px
$size-large: $size-base * 5; // 20px
$size-x-large: $size-base * 6; // 24px
$size-xx-large: $size-base * 7; // 28px
