div.dialog {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 540px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  padding: 0;
}

.dialogHeader {
  margin: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.dialogHeader h1 {
  flex: auto;
}

.dialogFooter {
  display: flex;
  flex: auto;
  margin: 16px;
}

.dialogFooter button {
  padding: 0;
  margin: 0;
  width: 100%;
}
