.grid {
  overflow: scroll;
  height: 100%;

  /* bug in chrome with subpixel render */
  padding-right: 0.5px;
}

.gridTable {
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;
  min-width: 100%;
}

.gridEmpty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gridHeader {
  top: 0;
  position: sticky;
  width: 120px;
  border-bottom: 1px solid #dee7ea;
  background-color: white;
  padding: 6px;
}

.gridCell {
  width: 120px;
  padding: 2px;
  border-left: 1px solid #dee7ea;
  border-right: 1px solid #dee7ea;
}

.gridHeaderClinic {
  margin-bottom: 6px;
  height: 2em;
}

.slot {
  border: 1px solid #dee7ea;
  border-radius: 5px;
  padding: 6px;
  height: 50px;
  cursor: pointer;
}

.slotTime {
  margin-bottom: 6px;
}

.slotActive {
  background-color: #e5fbfb;
  color: #2bbbbb;
  border-color: #c5f1f1;
}

.slotDisabled {
  background-color: #e3e3e3;
  color: #9b9b9b;
  border-color: #dee7ea;
}
