.userTypeWarning {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.logoutButton {
  float: right;
  margin-top: 16px;
}
