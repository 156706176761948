.Dropdown {
  width: 700px; /* Use 700px as width to much the design */
}

.buttonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.labelNotes {
  color: #6b7785;
}
