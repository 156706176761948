.modalActions {
  margin-top: 16px;
}

.modalBody {
  text-align: left;
  max-width: 300px;
  margin: 32px;
}

.modalConfirmLink {
  text-decoration: none;
}

.modalConfirmButton {
  min-width: 172px;
}

.modalContent {
  text-align: center;
}

.modalTopIcon {
  margin-bottom: 16px;
}
