/* These styles are more specific to sensibly override the react-big-calendar styles  */

.calendar {
  line-height: 1.5;
}

.calendar :global .rbc-header {
  padding: 10px;
}

.calendar :global .rbc-event-label {
  font-size: 14px;
}

.calendar :global .rbc-event-content {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto;
  min-height: 0;
}

.calendar :global .rbc-time-slot {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.calendar :global .rbc-toolbar {
  flex-direction: row-reverse;
}

.calendar :global .rbc-toolbar-label {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
}

.calendar :global .rbc-allday-cell {
  display: none;
}

.calendar .event {
  display: flex;
  flex-flow: row wrap;
  line-height: 1;
  padding-top: 6px;
}

.calendar .timeslot {
  min-height: 25px;
}

.calendar .isAdminEvent {
  background: repeating-linear-gradient(
    45deg,
    #265985,
    #265985 10px,
    #296ca5 10px,
    #296ca5 20px
  );
}
