.queueList {
  margin: 0 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.queueDescription {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #022345;
  margin-top: 16px;
  padding: 0 16px;
  font-size: 14px;
}

.queueItem {
  margin: 8px 0;
  padding: 0 8px;
  display: grid;
  grid-template-columns: 20px 1fr;
}

.itemChildren {
  grid-column-start: 2;
}

.queueItem.centered {
  align-items: center;
}

.queueItemContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.queueItemRow {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 16px;
  grid-column: span 2;
  padding: 8px;
  border-radius: 8px;
}

.queueItemRow:hover {
  background: #f3f6f9;
}

.queueItemRow.highlighted {
  background: #f3f6f9;
}

.queueItem.disabled .itemTitle,
.queueItem.disabled .itemLabel {
  opacity: 0.33;
}

.queueItem.disabled .queueItemRow:hover {
  background: inherit;
}

.itemTitle {
  font-weight: 600;
  color: #44525f;
  font-family: 'Inter', sans-serif;
}

.itemDescription {
  font-weight: 400;
  color: #728492;
  font-size: 12px;
  font-style: italic;
  font-family: 'Inter', sans-serif;
}

.itemLabel {
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.spinner {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keyDescriptor {
  display: flex;
  align-items: center;
  gap: 8px;
}

.keyDescriptor span {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #737376;
}

.keyDescriptors {
  display: flex;
  align-items: center;
  gap: 16px;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: 1px solid #e8e8e9;
  padding: 8px 16px;
}

.searchInput {
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100%;
  color: #728492;
  padding: 4px 8px;
}

.searchContainer {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid #e8e8e9;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: #ffa06a;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

.availability {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 2px solid white;
  position: absolute;
  bottom: -3px;
  right: -3px;
}
