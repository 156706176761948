.chatFooter {
  background-color: white;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 12px 18px;
  border-top: 1px solid #d7dadc;
  position: relative;
  border-bottom-left-radius: 8px;
}

.chatFooter button {
  margin-left: 12px;
  min-width: 7rem;
  margin-bottom: 0;
}

.chatInput {
  max-width: 100%;
  flex: auto;
  border: none;
  overflow: hidden;
  resize: none;
  color: #44525f;
  font-size: 16px;
}

textarea.chatInput:disabled {
  opacity: 0.7;
  background-color: transparent;
}

button.sendButton {
  border: none;
  background-color: transparent;
  min-width: 0;
  cursor: pointer;
}

button.sendButton:disabled {
  opacity: 0.7;
  cursor: auto;
}

button.sendButton svg.sendSVG > path {
  fill: #44525f;
}

button.sendButton:hover:not([disabled]) svg.sendSVG > path {
  fill: var(--highlight-blue);
  transition: fill 150ms ease-out;
}

button.attachmentButton {
  border: none;
  background-color: transparent;
  min-width: 0;
  cursor: pointer;
  margin-left: 0;
  margin-top: 5px;
}

button.attachmentButton:disabled {
  opacity: 0.7;
  cursor: auto;
}

button.attachmentButton svg.uploadSVG > path {
  fill: #44525f;
}

button.attachmentButton:hover:not([disabled]) svg.uploadSVG > path {
  fill: var(--highlight-blue);
  transition: fill 150ms ease-out;
}
