// ------------------------
// C O L O R S - tokens
// https://babylonpartners.atlassian.net/wiki/spaces/UD/pages/371130823/Design+Tokens
// Turquoise
$turquoise100: #2bbbbb;
$turquoise10: #e9f8f8;

// Purple
$purple100: #400099;
$purple10: #ece6f5;

// Pink
$pink100: #f45da9;
$pink10: #ffeef6;

// Orange
$orange100: #ff9e01;
$orange10: #fff5e6;
$orange-d20: darken($orange100, 20%);

// Yellow
$yellow100: #fcd920;
$yellow10: #fefbe9;

// Red
$red100: #ff3b2f;
$red10: #ffebea;
$red-d10: darken($red100, 10%);
$red-d20: darken($red100, 20%);

// Green
$green100: #4bd963;
$green10: #edfbef;

//Greys
$grey100: #282824;
$grey75: #637280;
$grey50: #bfc3cb;
$grey25: #dddfe1;
$grey10: #fafafa;

// White
$white100: #fff;

$traffic-light-risk-red-500: #d53232;
$traffic-light-positive-green-400: #008535;

// ------------------------
// C O L O R S - definitions

$primary-hex: $turquoise100 !default;
$primary: $primary-hex !default;
$primary-light: $turquoise10 !default;
$primary-dark: darken($primary-hex, 5%) !default;
$primary-d10: darken($primary-hex, 10%) !default;

$secondary: $grey75 !default;
$secondary-dark: $grey100 !default;
$secondary-light: #c5d0de !default;

$tertiary: $pink100 !default;
$tertiary-d10: darken($tertiary, 10%);

$quaternary: $purple100 !default;
$quaternary-d10: darken($quaternary, 10%);

$outline-dark: $grey100;

$positive: $green100;
$positive-light: lighten($positive, 5%);

$negative: $red100;
$negative-light: lighten($negative, 5%);
$negative-dark: darken($negative, 5%);

$unfocus-border: $secondary-light;
$focus-border: $primary; // TODO
$error: $red100;

$success: $positive; // TODO

$icon-color: $secondary-light;

// Distances
$input-height: 48px;

$small-input-height: 36px;
$small-input-font-size: 14px;

$medium-input-height: 44px;
$medium-input-font-size: 16px;

$large-input-height: 60px;
$large-input-font-size: 22px;

$border-radius: 8px;

// Fonts
$font-family: 'Visuelt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

@mixin font($weight: normal) {
  font-family: $font-family;
  font-weight: $weight;
  -webkit-font-smoothing: antialiased;
}

// Transitions
$transition-time: 0.3s;
$transition-ease: ease-in-out;

@mixin shimmerAnimation($duration: 1s, $width: 400px, $height: 50px) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: darken($white100, 5%);
  background: linear-gradient(
    to right,
    darken($white100, 5%) 8%,
    darken($white100, 10%) 18%,
    darken($white100, 5%) 33%
  );
  background-size: $width $height;

  @keyframes placeholderShimmer {
    0% {
      background-position: calc($width/-2) 0;
    }

    100% {
      background-position: calc($width/2) 0;
    }
  }
}
