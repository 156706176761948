.tag {
  background: #fff;
  color: inherit;
  font-weight: 700;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.header {
  padding-bottom: 0;
  margin-bottom: 32px;
}
