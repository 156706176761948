.slot {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dee7ea;
  border-radius: 5px;
  padding: 2px 3px;
  height: 50px;
}

.slotContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 3px;
}

.slotTime {
  margin: 0 5px 5px 0;
}

.slotActive {
  background-color: #e5fbfb;
  color: #2bbbbb;
  border-color: #c5f1f1;
}

.slotDisabled {
  background-color: #e3e3e3;
  color: #9b9b9b;
  border-color: #dee7ea;
}
