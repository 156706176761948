.Timeline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-height: 1px;
  grid-auto-rows: minmax(1px, auto);
}

.TimelineTime {
  position: relative;
  max-width: 36px;
  flex: 36px;
  padding-right: 3px;
}

.TimelineLine {
  flex: 1;
  display: block;
  height: 1px;
  background-color: #ededed;
  margin-top: 8px;
}
