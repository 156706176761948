.appointmentStatus {
  border: 1px solid var(--warning-tag-border);
  text-transform: uppercase;
  background: var(--white);
  font-size: 12px;
  line-height: 16px;
  padding: 0 5px;
  font-weight: bold;
  border-radius: 3px;
  color: var(--label-color);
}
