@font-face {
  font-family: 'Visuelt';
  src: url('./visuelt-regular.eot');
  src: url('./visuelt-regular.eot?#iefix') format('embedded-opentype'),
    url('./visuelt-regular.woff2') format('woff2'),
    url('./visuelt-regular.woff') format('woff'),
    url('./visuelt-regular.ttf') format('truetype'),
    url('./visuelt-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Visuelt';
  src: url('./visuelt-light.eot');
  src: url('./visuelt-light.eot?#iefix') format('embedded-opentype'),
    url('./visuelt-light.woff2') format('woff2'),
    url('./visuelt-light.woff') format('woff'),
    url('./visuelt-light.ttf') format('truetype'),
    url('./visuelt-light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Visuelt';
  src: url('./visuelt-medium.eot');
  src: url('./visuelt-medium.eot?#iefix') format('embedded-opentype'),
    url('./visuelt-medium.woff2') format('woff2'),
    url('./visuelt-medium.woff') format('woff'),
    url('./visuelt-medium.ttf') format('truetype'),
    url('./visuelt-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Visuelt';
  src: url('./visuelt-bold.eot');
  src: url('./visuelt-bold.eot?#iefix') format('embedded-opentype'),
    url('./visuelt-bold.woff2') format('woff2'),
    url('./visuelt-bold.woff') format('woff'),
    url('./visuelt-bold.ttf') format('truetype'),
    url('./visuelt-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
