.dialog {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 9px 3px rgba(74, 74, 74, 0.4);
  padding: 20px;
  position: relative;
  width: 430px;
}

.close {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.details {
  margin-bottom: 10px;
}

.consentExpiredNotice {
  display: inline-flex;
  background-color: var(--background-negative);
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 12px;
  color: var(--grey-100);
}
