.SingleCol {
  padding: 1em;
  text-align: center;
}

.ColumnMissingDataText {
  color: rgba(0, 0, 0, 0.3);
}

.pagination {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-bottom: 2em;
}

.clinicianCell {
  display: flex;
}

.consultantAvailabilityLink {
  margin-left: 12px;
  color: var(--teal-color);
  transition: color 0.2s ease;
}
