@import '../colors.css';

.commaList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 4px;
}

.commaList > li::after {
  content: ',';
}

.commaList > li:last-child::after {
  content: '';
}

.pill {
  display: inline-block;
  text-transform: capitalize;
  padding: 4px 8px;
  border-radius: 50px;

  color: var(--color-pill-text);
  background: var(--color-pill-background);
}

.withTitle {
  flex-grow: 1;
}

.withTitle > *:first-child {
  gap: 8px;
  display: flex;
  align-items: flex-start;
}

.withTitleTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.withTitle > *:last-child {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-text-secondary);
}

.withTitlePills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.withTitlePills > * {
  font-size: 12px;
  color: var(--color-accent-text);
  background: var(--color-accent-background);
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: bold;
  text-transform: uppercase;
}

.unknown {
  color: #c6c6c6;
}
