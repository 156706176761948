.slotBlock {
  display: flex;
  gap: 24px;
}

.times {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.time {
  font-weight: bold;
  font-size: 16px;
}

.duration {
  font-size: 14px;
}

.card {
  display: flex;
  flex-grow: 1;
  border-radius: 4px;
}

.cardAvailableSlot {
  border: 1px dashed var(--shadow-edge);
  background-color: var(--active-grey);
}

.type {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  padding: 20px 0;
  color: var(--black);
  align-items: center;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 16px;
  justify-content: center;
}

.details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 16px;
}
