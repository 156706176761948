.Message {
  display: block;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-bottom: 1em;
}
