@mixin button-reset {
  cursor: pointer;
  appearance: none;
  border-radius: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  border: 0;
  padding: 0;
}
