.text {
  margin-bottom: 18px;
}

.loader {
  padding: 20px;
}

.anchor {
  color: var(--anchor-color);
  margin-right: 10px;
}

.cancelAppointment {
  margin-top: 12px;
}

.cancelLabel {
  margin-top: 20px;
}
