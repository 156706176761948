.ClinicianAvatarAndName {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}

.ClinicianAvatarAndNameAvatar {
  border-radius: 50%;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.ClinicianAvatarAndNameAvatarFallback {
  background-color: var(--grey-75);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: var(--white-color);
  align-self: flex-start;
}

.ClinicianDetailsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12px;
  flex-direction: column;
}
