.overlay {
  background-color: rgba(255, 255, 255, 0.8);
}

.dialog {
  width: 50%;
  height: 100vh;
  border-left: 1px solid var(--shadow-edge);
  background-color: var(--white);
  transform: translateY(-50%);
  right: 0;
  left: auto;
}

.header {
  display: flex;
  margin-bottom: 60px;
}

.heading {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  margin: 0;
}

.closeButton {
  margin-left: auto;
  border: 0;
  width: 40px;
  height: 40px;
  font-size: 32px;
  line-height: 1;
  font-weight: 300;
  color: var(--babylon-violet);
  background-color: var(--active-grey);
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  overflow: hidden;
}

.closeButton:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
}

.closeButton:hover {
  opacity: 0.7;
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
