@media screen {
  .onlyPrint {
    display: none;
  }

  .message {
    padding: 8px 12px;
    border-radius: 2px 16px 16px 16px;
    margin-bottom: 12px;
    max-width: 60%;
    min-width: 100px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);

    /* babylon/body */
    color: #44525f;
  }

  .message.mine {
    background-color: var(
      --medkit-button-primary-background-color,
      var(--medkit-button-color-one, #400099)
    );
    color: white;
    align-self: flex-end;
    border-radius: 16px 2px 16px 16px;
    border: 1px solid transparent;
  }

  .message.mine.uploading {
    background-color: var(
      --medkit-button-primary-background-color,
      var(--medkit-button-color-one, #a27fd3)
    );
  }
}

@media print {
  .message {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
  }

  .messageSubContent > span {
    font-size: 14px;
    line-height: 24px;
  }
}

.attachmentUpload {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.attachment {
  display: flex;
  justify-content: flex-end;
}

.attachmentIcon {
  padding-right: 5px;
}

.messageSubContent {
  display: flex;
  justify-content: space-between;
}

.removeIcon {
  cursor: pointer;
}
