.dropdownMenu {
  width: 200px;
  position: absolute;
  background: #fff;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(40, 40, 36, 0.21);
  border-radius: 8px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}

button.textButton {
  border: none;
  background: none;
  cursor: pointer;

  font-family: 'Visuelt', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding: 8px;
  border-radius: 8px;
}

button.menuButton {
  width: 100%;
}

button.menuButton.normal {
  color: black;
}

button.menuButton.alert {
  /* babylon status error */
  color: #d13400;
}

button.menuButton:hover {
  background: #f3f6f9;
}

.menuDivider {
  border-bottom: 1px solid #d7dadc;
  margin-left: -8px;
  margin-right: -8px;
}
