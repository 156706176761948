.messagesContainer {
  display: flex;
  flex: auto;
  align-items: flex-start;
  overflow-y: scroll;
  flex-direction: column;
  background: var(--color-pill-background);
  justify-content: flex-start;
}

.messagesContainer > svg {
  margin: 0 auto;
  padding: 12px 0;
  width: 10%;
  height: auto;
}

@media print {
  .noPrint {
    display: none;
  }
}
