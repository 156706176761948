.status {
  color: var(--grey-800);
  font-weight: 700;
  margin-bottom: 2px;
}

.container {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 54px;
  border: 2px solid var(--grey-800);
}

.loadingBlock {
  height: 88px;
  margin-bottom: 54px;
}
