.ReallocateShiftCTA {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14);
  padding: 14px 40px;
  z-index: 100;
}

.ReallocateShiftCTAError {
  margin-right: 24px;
}
