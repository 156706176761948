.appointmentDetails {
  margin-bottom: 16px;
}

.appointmentDetailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointmentDetailsItem {
  margin-top: 8px;
  white-space: nowrap;
}
