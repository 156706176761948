@import '../../node_modules/@babylon/medkit-tokens/colors/colors.scss';

// from webapp

// ------------------------
// C O L O R S
// https://babylonpartners.atlassian.net/wiki/spaces/UD/pages/371130823/Design+Tokens

// Turquoise
$turquoise100: #2bbbbb;
$turquoise10: #e9f8f8;

// Purple
$purple100: #400099;
$purple50: #987ac3;
$purple10: #ece6f5;
$darkpurple: #2b065e;
$darkerpurple: #24054f;
$violet: #e0d6ef;

// Blue
$blue100: #4481ff;
$solitude: #e3eaef;

// Pink
$pink100: #f45da9;
$pink10: #ffeef6;
$cerise: #ff518a;
$brightpink: #f45da9;

// Orange
$orange100: #ff9e01;
$orange10: #fff5e6;
$orange-d20: darken($orange100, 20%);

// Yellow
$yellow100: #fcd920;
$yellow10: #fefbe9;

// Red
$red120: #c72e15;
$red100: #d53232;
$red40: #db7866;
$red10: #ffebea;
$red-d20: darken($red100, 20%);
$red-l10: lighten($red100, 10%);

// Green
$green100: #4bd963;
$green10: #edfbef;
$green-d20: darken($green100, 20%);

//Greys
$grey100: #282824;
$grey90: #32383d;
$shuttle-gray: #54606a;
$grey80: #454d54;
$grey75: #637280;
$slategrey: #728492;
$darkgray: #647481;
$mediumgray: #9ea9b3;
$grey50: #bfc3cb;
$grey25: #dddfe1;
$grey15: #f2f2f3;
$grey10: #fafafa;
// these colours are barely different but both
// shades are used in different places so leaving as-is
$lightgray: #f3f6f9;
$light-gray: #f3f6f8;

// Misc
$white100: #fff;
$black100: #000;
$teal: #005870;

// TODO: roll out l[%] d[%] naming convention to all colours

$primary-l50: lighten($purple100, 50%);
// leaving a gap for possible extra-light here
$primary-d10: darken($purple100, 10%);

$secondary: $purple100;
$secondary-dark: darken($secondary, 10%);

$tertiary: $pink100;
$tertiary-d10: darken($tertiary, 10%);

$grey-hover: #f9f9f9;
$grey-extra-light: #f0f3f7;
$grey-light: #dfdfdf;
$grey: #c5d0de;
$grey-dark: #637280;
$grey-extra-dark: #353a46;
$icon-background: #868e96;

// https://babylonpartners.atlassian.net/wiki/spaces/UD/pages/371130823/Design+Tokens#DesignTokens-Greys

// TODO colors needs to be named by color not the where are used
$label-color: #343a46;
$outline-dark: #3b4859;
$focus-border: #319bf5;
$error: $red100;
$success: $green100;

$positive: $green100;
$positive-light: $green10;
$positive-dark: darken($positive, 10%);
$positive-extra-dark: darken($positive, 30%);
$positive-feedback: $positive;

$negative: $red100;

$negative-dark: darken($negative, 10%);
$negative-light: lighten($negative, 10%);
$negative-extra-light: #f7d7da;

$nhs: rgb(0, 94, 184);
$nhs-hover: rgb(0, 75, 146);
$nhs-light: rgba(0, 94, 184, 0.1);

$warning: $orange100;
$highlight: #f8e71c;
$highlight-light: $yellow10;

$negative-light: $red10;
$negative-dark: #b00020;

$icon-color: #4a4a4a;

$transparent-grey: rgba(0, 0, 0, 0.4);
$border-radius: 4px;

$neutral-feedback: #516173;

$date-picker-modal-width: 337px;

// from @babylon/medkit

$primary-active: lighten($purple100, 15%);
$primary-light: lighten($purple100, 60%);

// Overriding colours as design-tokens is currently deprecated while transitioning to zeroheight
$primary-cta: $purple100;
$on-primary-cta: #fff;
$neutral-feedback: #516173;
