.container {
  display: inline-flex;
  background-color: var(--background-positive);
  border: 1px solid var(--grey-25);
  border-radius: 4px;
  padding: 4px;
}

.container > * {
  padding: 4px;
}

.postalCode,
.lastName {
  text-transform: uppercase;
}

.firstName {
  text-transform: capitalize;
}
