.legendKey {
  display: inline-flex;
  height: 20px;
  line-height: 20px;
  align-items: center;
  padding: 5px 20px 5px 0;
  max-width: 1100px;
}

.iconKey {
  composes: legendKey;
  position: relative;
  top: -3px;
}

.keyBox {
  height: 20px;
  width: 20px;
  display: inline-flex;
  margin-right: 10px;
  color: var(--grey-200);
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.keyBox svg {
  margin: 0;
  padding: 0;
  max-height: 20px;
  max-width: 20px;
}

.legend {
  margin-top: 20px;
}
