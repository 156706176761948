.onlineToggle {
  background-color: var(--button-secondary-disabled-color);
}

.onlineToggle[aria-checked='true'] {
  background-color: var(--button-success-background);
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  margin-top: 0;
}

.centered {
  display: grid;
  place-content: center;
}

.inConversation {
  margin-right: 12px;
  opacity: 0.6;
}

.memberWaitTimeContainer {
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.separator {
  border-top: 1px solid #d7dadc;
}

.memberWaitTime {
  display: flex;
  flex-direction: column;
}

.memberWaitTimeBanner {
  font-weight: bold;
}

.messagesBody {
  border: 1px solid #d7dadc;
  border-radius: 0 0 8px 8px;
  position: relative;
  box-shadow: 0 0 4px #ebf2f9;
  background: #f3f6f9;
  display: flex;
  flex-direction: column;
  clip-path: inset(0 -1px -1px -1px);
  border-top: 0;
  height: calc(100vh - 230px);
}

.messages {
  display: flex;
  flex: auto;
  align-items: flex-start;
  flex-direction: column-reverse;
  padding: 38px;
  overflow-y: scroll;
}

.messages:last-child {
  margin-bottom: 0;
}

.dialogDescription {
  margin: 0 16px;
  font-size: 16px;
}

.typingIndicator {
  position: absolute;
  background: #f3f6f9;
  width: 100%;
  left: 0;
  bottom: 60px; /* footer height */
  padding: 8px 16px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* Babylon/Text/body */
  color: #44525f;
}

.customTabs {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.timer {
  font-size: 9px;
}

.messageCountPill {
  align-items: center;
  border: 1px solid var(--medkit-stepper-base, #54606a);
  border-radius: 50%;
  box-sizing: content-box;
  display: grid;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
  background: green;
  color: white;
  font-size: 12px;
}

.closeConverstionTabBtn {
  width: 40%;
}

.activeConversationContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.activeConversationContainer > div {
  display: flex;
  flex-direction: column;
}

.activeConversationContainer > div:first-child {
  flex: 1;
}
