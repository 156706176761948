.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form :global .core-form-label {
  color: var(--text);
}

.submitButton {
  background-color: var(--babylon-violet);
  font-size: 16px;
  margin-left: auto;
}

.submitButton:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 2px;
}

.submitButton:hover,
.submitButton:active {
  background-color: var(--babylon-violet);
  opacity: 0.8;
}

.cancelReason {
  max-width: 280px;
}

.cancelReason :global .core-select__control {
  height: 34px;
  min-height: 0;
}

.cancelReason :global .core-select__value-container {
  padding: 4px;
}
