.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.container div {
  min-height: 50px;
  flex: 1;
  width: 100%;
}

.scrollContainer {
  display: block;
  max-width: 100%;
  overflow: auto;
  margin-left: auto;
  margin-right: 0;
}

.wrapper {
  position: relative;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 233px;
  max-width: 100%;
}

.visibilityTotalResults {
  margin: 20px 0;
}
