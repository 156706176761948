$text-color: #353a46;
$spacing--m: 24px;
$spacing--l: 32px;
$secondary: #400099 !default;
$error: rgb(255, 0, 0);
$header-width: 47.375rem;
$content-width: 47.375rem;

$z-index-overlay: 999;
$font-size--m: 23px;
$question-margin-bottom: 56px;

// ------------------------
// H E L P E R S

$transition-speed: 0.3s;
$transition-ease: ease-in-out;

$tablet-start-width: 768px;

@mixin fromTablet {
  @media (min-width: #{$tablet-start-width}) {
    @content;
  }
}

@mixin buttonReset {
  cursor: pointer;
  appearance: none;
  border-radius: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  padding: 0;
}
