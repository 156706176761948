.ConfirmReallocationModal {
  width: 480px;
}

.ConfirmReallocationModalDescription {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
}

.ConfirmReallocationModalDescriptionEntry:not(:first-child) {
  margin-top: 16px;
}

.ConfirmReallocationModalCTA {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.ConfirmReallocationModalCTAConfirm {
  margin-left: 20px;
}

.ConfirmReallocationModalDescriptionEntryTitle {
  margin-bottom: 8px;
}
