.dateText {
  color: var(--grey-800);
}

.boldText {
  font-weight: 700;
}

.card {
  background-color: var(--active-grey);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.textContainer {
  flex-direction: column;
  align-items: flex-start;
}
