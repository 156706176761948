.message {
  background-color: white;
  padding: 8px 12px;
  border-radius: 2px 16px 16px 16px;
  margin-bottom: 12px;
  max-width: 60%;
  min-width: 100px;
  border: 1px solid rgba(0, 0, 0, 0.08);

  /* babylon/body */
  color: #44525f;
}

.message.mine {
  background-color: var(
    --medkit-button-primary-background-color,
    var(--medkit-button-color-one, #400099)
  );
  color: white;
  align-self: flex-end;
  border-radius: 16px 2px 16px 16px;
  border: 1px solid transparent;
}

.message.mine.uploading {
  background-color: var(
    --medkit-button-primary-background-color,
    var(--medkit-button-color-one, #a27fd3)
  );
}

.attachmentUpload {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.showDeleteAttachmentModalBody {
  margin: 0 16px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #306596;
}

.showDeleteAttachmentButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
