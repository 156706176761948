button.icon {
  padding: 11px;
  padding-bottom: 8px;
  background-color: var(--active-grey);
  border: none;
  cursor: pointer;
}

.iconSVG {
  color: var(--babylon-violet);
}
