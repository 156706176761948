.details {
  background-color: var(--active-grey);
  border-radius: 8px;
  padding: 32px;
}

.name {
  font-size: 24px;
  margin: 0;
}

.info {
  font-size: 16px;
  margin-top: 16px;
}

.infoTitle {
  font-weight: bold;
}
