$form-breakpoint: 458px;
$small-breakpoint: 576px;
$small-landscape-breakpoint: 667px;
$medium-breakpoint: 769px;
$large-breakpoint: 992px;
$extra-large-breakpoint: 1140px;

@mixin form {
  @media (min-width: #{$form-breakpoint}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$small-breakpoint - 1px}) {
    @content;
  }
}

@mixin tablet-mini {
  @media (min-width: #{$small-breakpoint}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$medium-breakpoint}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$large-breakpoint}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$extra-large-breakpoint}) {
    @content;
  }
}

@mixin large-desktop-with-space {
  @media (min-width: #{$extra-large-breakpoint + 32}) {
    @content;
  }
}

@mixin less-than-tablet {
  @media (max-width: #{$medium-breakpoint}) {
    @content;
  }
}

// Introducing the below as it's currently used in IWE squad and can be updated
// with breakpoints as defined by NVL
// Leaving the above in place so they can be moved over gradually

/* stylelint-disable at-rule-no-unknown, at-rule-empty-line-before  */

$breakpoints: (
  'small': (
    min-width: 620px,
  ),
  'medium': (
    min-width: 768px,
  ),
  'large': (
    min-width: 1025px,
  ),
  'xlarge': (
    min-width: 1280px,
  ),
  'xxlarge': (
    min-width: 1440px,
  ),
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
