.wrapper {
  overflow: hidden;
  max-height: 0;
  transition: 0.3s max-height;
}

.button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.buttonArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f9fe;
  height: 24px;
  width: 24px;
  border-radius: 2px;
  margin-right: 8px;
}

.buttonArrowIcon {
  transition: 0.3s all;
  height: 8px;
  width: 7px;
}

.containerOpen .buttonArrowIcon {
  transform: rotate(90deg);
}

.innerWrapper {
  padding-top: 20px;
}
