.heading {
  margin: 0 0 12px 0;
}

.intro {
  margin: 0 0 12px 0;
}

.create_wait_time_configs_button {
  margin: 0 0 24px 0;
}
