.LoadMoreButton {
  padding-top: 40px;
  margin-left: 12px;
}

.ActionContainer {
  display: flex;
  flex-direction: row;
}

.EditTitleInput {
  margin-top: 0;
  margin-bottom: 0;
}

.EditDocumentHeader {
  margin: 4px;
}

.EditDocumentTitle {
  composes: EditDocumentHeader;
  margin-bottom: 24px;
}

.EditModalButtonContainer {
  composes: ActionContainer;
  margin-top: 40px;
  justify-content: end;
}

.EmptyDocumentMessage {
  margin: 12px;
}
