.sessionInfoBlock {
  display: flex;
  gap: 24px;
}

.sessionInfoTime {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

.sessionInfoDetailContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
}

.sessionInfoDetails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 16px;
  font-weight: bold;
}
