:root {
  /* TODO: replace with Babylon colors */
  --color-elements-background: #fff;
  --color-elements-border: #e8e8e9;
  --color-text-secondary: #54606a;
  --color-accent-background: #f7d84c;
  --color-accent-text: #fff;
  --color-text-heading: #32383d;

  --color-pill-background: #f3f6f9;
  --color-pill-text: #6b778c;

  --babylon-brand-primary: #022345;
}
