.calendar {
  width: 100%;
  margin-bottom: 10px;
}

.calendar :global .react-datepicker__month-container {
  width: 100%;
}

.calendar :global .react-datepicker__day-names {
  display: inline-flex;
  width: 100%;
}

.calendar :global .react-datepicker__day-names .react-datepicker__day-name {
  width: 100%;
}

.calendar
  :global
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__day--selecting-range-start) {
  background-color: var(--white);
}

.calendar :global .react-datepicker__week {
  display: inline-flex;
  width: 100%;
}

.calendar .calendarDay {
  width: 100%;
}
