.translationLanguage {
  margin-top: -10px;

  > div {
    > div {
      margin-bottom: 2px;
    }
  }

  label {
    color: #6b7785;
  }

  &.disabled {
    label {
      color: #ccc;
    }
  }
}
