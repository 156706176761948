.ClinicianTimelineWrapper {
  height: 100%;
  width: 100%;
  grid-template-rows: 77px;
}

.ClinicianTimeline {
  position: relative;
  overflow: auto;
}

.ClinicianTimelineRange {
  position: absolute;
  width: 100%;
  background-color: rgba(43, 187, 187, 0.07);
  height: 200px;
  border-top: 1px solid var(--primary-100);
  border-bottom: 1px solid var(--primary-100);
}

.ClinicianTimelineSlotWrapper {
  position: relative;
  height: 100%;
  margin: 5px 13px 0;
}

.ClinicianTimelineShiftSlot {
  position: absolute;
  padding-left: 36px;
  margin-top: 8px;
  width: 100%;
}

.ClinicianTimelineShiftSlot > *:not(:last-child) {
  margin-bottom: 4px;
}

.ClinicianTimelineLoadingContainer {
  width: 100%;
}

.ClinicianProfileSection {
  display: flex;
  padding: 21px 16px;
}
