.requirementsContainer {
  margin-top: 10px;
}

.requirementsContainer label {
  line-height: 30px;
  color: #637280;
  font-family: AvenirNext-DemiBold;
}

.requirementsContainer label[disabled] {
  color: #ccc;
}

.requirementsContainer fieldset {
  margin: 0px !important;
  padding: 14px 8px 5px 8px !important;
}

.requirementsContainer fieldset:first-child {
  border-bottom: none;
}

.requirementsContainer fieldset:not(:first-child) {
  border-top: none;
}

.requirementsContainer .translationLanguageAttrributes,
.requirementsContainer .chaperoneAttributes {
  padding-left: 9px;
  padding-right: 9px;
}

.requirementsContainer * {
  font-size: 14px;
}
