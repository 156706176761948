.PopoverDialog {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 9px 3px rgba(74, 74, 74, 0.4);
  padding: 20px;
  position: relative;
  width: 430px;
}

.PopoverDialogReferenceHidden {
  visibility: hidden;
  pointer-events: none;
}

.PopoverArrow::before {
  content: '';
  background: white;
  width: 0.6rem;
  height: 0.6rem;
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
}

.PopoverDialog[data-popper-placement^='right'] .PopoverArrow {
  left: 0;
}

.PopoverDialog[data-popper-placement^='left'] .PopoverArrow {
  right: 0;
}

.PopoverDialog[data-popper-placement^='top'] .PopoverArrow {
  bottom: 0;
}

.PopoverDialog[data-popper-placement^='bottom'] .PopoverArrow {
  top: 0;
}
