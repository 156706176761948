.tag {
  text-transform: uppercase;
  padding: 2px 4px;
}

.type {
  background-color: var(--violet-light);
}

.consumerNetwork {
  background-color: var(--primary-10);
}

.moreDetails {
  appearance: none;
  border: 0;
  padding: none;
  cursor: pointer;
}
