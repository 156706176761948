.status {
  --member-task-pending: #d47602;
  --member-task-completed: #008535;
}

.pending {
  composes: status;
  border: 2px solid var(--member-task-pending);
}

.completed {
  composes: status;
  border: 2px solid var(--member-task-completed);
}
