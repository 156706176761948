.patientHeader {
  min-height: 72px;

  padding: 16px;

  /* functional divider color */
  border-bottom: 1px solid #d7dadc;
  border-top-left-radius: 8px;
  display: flex;
  background: white;
  align-items: center;
  z-index: 1;
}

.patientHeader h3 {
  /* flowkit/charcoal */
  color: #222;
  font-weight: 700;
}

.patientHeader .accountTags {
  margin-bottom: 0;
  margin-left: 12px;
}

.membershipDetails {
  display: flex;
  align-items: center;
  color: var(--babylon-violet);
}

.membershipDetails span {
  margin-right: 8px;
}

button.menuButton {
  border: none;
}

button.iconButton {
  background: none;
  cursor: pointer;
  border: 1px solid #e8e8e9;
  padding: 8px;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
}

button.iconButton:hover {
  border-color: var(--highlight-blue);
  transition: border-color 150ms ease-out;
}

button.iconButton.onlyIcon {
  padding: 0;
  border: none;
}

.detailsBar {
  margin-bottom: -72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 10px;
  background: #fff;

  /* babylon/body */
  color: #54606a;

  z-index: 0;
}

.detailsBar.active.preventAnimation,
.detailsBar.inactive.preventAnimation {
  animation-duration: 0ms;
}

.detailsBar.active {
  animation: slide-show 300ms ease-out;
}

.detailsBar.inactive {
  transform: translateY(-72px);
  animation: slide-hide 300ms ease-out;
}

@keyframes slide-hide {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-72px);
  }
}

@keyframes slide-show {
  0% {
    transform: translateY(-72px);
  }

  100% {
    transform: translateY(0);
  }
}

.detailsTitle {
  font-weight: 600;
  font-size: 14px;

  /* Babylon/Brand/primary */
  color: #022345;
}

.showConversationIdModelBody {
  margin: 0 16px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.showConversationButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.showConversationButtons button {
  width: auto;
  display: flex;
  gap: 8px;
  padding: 8px 16px;
}

.greenButton::before {
  background: #00bfa5;
}

.greenButton > span {
  color: #fff;
}
