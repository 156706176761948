.SingleCol {
  padding: 1em;
  text-align: center;
}

.ColumnMissingDataText {
  color: rgba(0, 0, 0, 0.3);
}

.ShiftsListing {
  margin-top: 2em;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-bottom: 2em;
}

.consultantIdCell {
  display: flex;
}

.consultantAvailabilityLink {
  margin-left: auto;
  color: var(--teal-color);
  transition: color 0.2s ease;
}

.download {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-left: auto;
  font-size: 16px;
}

.downloadError {
  color: var(--error-color);
  font-size: 14px;
}

.downloadIcon {
  margin-right: 10px;
}
