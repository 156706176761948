.intent {
  margin-bottom: 20px;
}

.quoted::before {
  content: open-quote;
}

.quoted::after {
  content: close-quote;
}

.container {
  flex: auto;
  text-align: center;
  background: white;
  margin-top: -10px;
  padding-top: 10px;
  min-height: 30vh;

  height: calc(100vh - 360px);

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.confirmButton {
  width: 380px;
  margin: 0;
  margin-bottom: 20px;
}

.patientDetailsColumn {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.idsColumn {
  width: 40%;
  display: flex;
  gap: 4px;
}

.idsColumn .labels {
  width: 40%;
}

.idsColumn .values {
  width: 60%;
}

.columns {
  flex-direction: row;
  display: flex;
  gap: 8px;
}

.card {
  --background-secondary: #fcfcfc;
  --divider: #d7dadc;
  background: var(--background-secondary);
  border: 1px solid var(--divider);
  border-radius: 5px;
  padding: 8px;
  width: 410px;
  text-align: left;
  margin-bottom: 28px;
}

.memberName {
  margin-top: -12px;
}

.cardSpinner {
  display: grid;
  place-content: center;
  min-height: 130px;
}

.text.lg {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.text.bold {
  font-weight: 700;
}

.text.sm {
  font-size: 12px;
}

.text.md {
  font-size: 16px;
}

.text {
  font-family: 'Visuelt', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.text.highlight {
  --teal-highlight: #005870;
  color: var(--teal-highlight);
}

.text.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text.underline {
  text-decoration-line: underline;
}
