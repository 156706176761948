@import '../../global-constants';

$base-input-background: var(--medkit-input-background, $background);
$base-input-font-family: var(--medkit-input-font-family, $font-family);
$base-input-font-size: var(--medkit-input-font-size, $base-font-size);
$base-input-height: var(--medkit-input-height, rem-calc(48));
$base-input-margin: var(--medkit-input-margin, rem-calc(24));

// Outer border
$base-input-border-color-active: var(
  --medkit-input-border-color-active,
  $purple50
);
$base-input-border-color-error-active: var(
  --medkit-input-border-color-error-active,
  $red40
);
$base-input-border-width: var(--medkit-input-border-width, rem-calc(4));
// Bit of a hack way of adding the inverse of CSS variable.
$base-input-border-width-offset: calc(
  #{rem-calc(0)} - #{$base-input-border-width}
);

$base-input-border-radius: var(--medkit-input-border-radius, 0);

// Inner border
$base-input-box-shadow-color: var(--medkit-input-box-shadow-color, $grey-500);
$base-input-box-shadow-color-active: var(
  --medkit-input-box-shadow-color-active,
  $primary
);
$base-input-box-shadow-color-error-active: var(
  --medkit-input-box-shadow-color-error-active,
  $red120
);
$base-input-box-shadow-width: var(--medkit-input-border-width, rem-calc(2));

// Label
$base-input-label-color: var(--medkit-input-label-color, $grey-900);
$base-input-label-font-family: var(
  --medkit-input-label-font-family,
  $base-input-font-family
);
$base-input-label-font-size: var(
  --medkit-input-label-font-size,
  $base-font-size
);
$base-input-label-margin: var(--medkit-input-label-margin, rem-calc(4));
$base-input-label-line-height: var(--medkit-input-label-height, rem-calc(24));

// Non themable
$base-input-max-width: calc(100% + (#{$base-input-border-width} * 2));
