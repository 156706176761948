.form {
  padding: 1em;
  border-radius: 0.4em;
  background-color: var(--white);
  border: 1px solid #ebebeb;
  box-shadow: 0 1px 3px rgb(0 0 0 / 8%);
}

.heading {
  margin: 0;
}

.filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 1em;
}

.filter {
  font-size: 14px;
  line-height: 1;
}

.filterButtons {
  display: flex;
  gap: 1rem;
  justify-content: right;
}
